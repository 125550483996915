import styled from "styled-components"
import colors from "../../utils/styles/colors";

export function Title({ title, color }) {  
  const Text = styled.h1`
    font-family: "Ubuntu", sans-serif;
    color: ${color ? color : colors.white}
  `;

  return(
    <Text>
      {title}
    </Text>
  )
}
