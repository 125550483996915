import colors from "../../utils/styles/colors";
import { LayoutButton } from "./styled";

export function Button({ text, onClick, type, disabled }) {

  const style = !disabled ? {
      backgroundColor: colors.red,
      cursor: "pointer"
    } :
    {
      backgroundColor: colors.redLigth,
      cursor: "not-allowed"
    }

  return (
    <LayoutButton onClick={onClick} type={type} disabled={disabled} style={ style }>
      {text}
    </LayoutButton>
  )
}

