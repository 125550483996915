import styled from "styled-components";
import colors from "../../utils/styles/colors";

export const LayoutButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    color: ${colors.white};
    cursor: pointer;
    margin: 20px auto;
    /* background-color: ${colors.red}; */
`;
