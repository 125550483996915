export const adpaterFormOrder = (form) => {
  return { 
    ...form,
    customerPhone: '+55' + form.customerPhone.replace(/\W/g, ""),
    customerCpf: form.customerCpf.replace(/\W/g, ""),
    cardNumber: form.cardNumber.replace(/\W/g, ""),
    cardValidity: form.cardValidity.replace(/\W/g, ""),
    artistId: 1,
  }
}
