const maskPhone = (event) => {
  event.currentTarget.maxLength = 15

  let value = event.currentTarget.value

  value = value.replace(/\D+/g, '');

  value = value.replace(/^(\d{2})(\d)/, "($1) $2")
  value = value.replace(/^(\(\d{2}\) )(\d{5})(\d)/, "$1$2-$3")

  event.currentTarget.value = value

  return event
}

const maskCpf = (event) => {
  event.currentTarget.maxLength = 14

  let value = event.currentTarget.value

  value = value.replace(/^(\d{3})(\d)/, "$1.$2")
  value = value.replace(/^(\d{3}.)(\d{3})(\d)/, "$1$2.$3")
  value = value.replace(/^(\d{3}.)(\d{3}.)(\d{2}.)(\d)/, "$1$2$3-$4")

  event.currentTarget.value = value

  return event
}

const maskCardNumber = (event) => {
  event.currentTarget.maxLength = 19

  let value = event.currentTarget.value

  value = value.replace(/\D+/g, '');

  value = value.replace(/^(\d{4})(\d)/, "$1 $2")
  value = value.replace(/^(\d{4} )(\d{4})(\d)/, "$1$2 $3")
  value = value.replace(/^(\d{4} )(\d{4} )(\d{4})(\d)/, "$1$2$3 $4")

  event.currentTarget.value = value

  return event
}

const maskCardValidity = (event) => {
  event.currentTarget.maxLength = 5

  let value = event.currentTarget.value

  value = value.replace(/^(\d{2})(\d)/, "$1/$2")

  event.currentTarget.value = value

  return event
}

export { maskPhone, maskCpf, maskCardNumber, maskCardValidity }

