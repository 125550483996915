import { Text } from "../Text";
import { ContainerCard, Emoji } from "./styled";

export function CardEmoji({ emoji, text }) {
  return (
    <ContainerCard>
      <Emoji src={emoji} />
      <Text text={text} />
    </ContainerCard>
  )
}
