import styled from "styled-components";

export const ContainerCard = styled.div`
  width: 240px;
  height: 200px;
  margin: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Emoji = styled.img`
  width: 100px;
  margin: auto;
`;
