const colors = {
  white: "#ffffff",
  black: "#000000",
  red: "#E1394A",
  redLigth: "#d49097",
  green: "#5CAB69",
  gray: "#322634",
  blue: "#474d97",
  yellow: "#ecb541",
}

export default colors;
