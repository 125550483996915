import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { ContainerPage, IconApproved } from "./styled";
import { Text } from "../../components/Text";
import colors from "../../utils/styles/colors";
import approved from "../../assets/images/approved.png";

export function Success() {
  return(
    <>
      <Header />
        <ContainerPage>
          <IconApproved src={approved} />
          <Text text='Seu pagamento foi aprovado!' color={colors.gray} />
          <Text text='Logo o artista enviará a mensagem solicitada!' color={colors.gray} />
        </ContainerPage>
      <Footer />
    </>
  )

}
