import * as yup from "yup"
import validarCpf from "validar-cpf"

function cpf(cpf) {
  return this.test('isCpf', cpf, function (value) {
    const { path, createError } = this

    if (!value) {
      return createError({ path, message: "Informe o CPF"})
    }

    if (!validarCpf(value)) {
      return createError({ path, message: "CPF inválido"})
    }

    return true
  })
}

function cardValidity(validity) {
  return this.test('isCardValidity', validity, function(value) {
    const { path, createError } = this

    if (!value) {
      return createError({ path, message: "Infome uma data de validade" })
    }

    const monthAndYear = value.split('/')

    if (parseInt(monthAndYear[0]) > 12) {
      return createError({ path, message: "Mês invalido"})
    }

    if ((new Date().getYear() - 100) > parseInt(monthAndYear[1])) {
      return createError({ path, message: "Ano invalido" })
    }

    if ((new Date().getYear() - 100) === parseInt(monthAndYear[1])) {
      if ((new Date().getMonth() + 1) < monthAndYear[0]) {
        return createError({ path, message: 'Data invalida' })
      }
    }

    return true
  })
}

yup.addMethod(yup.string, "cpf", cpf)
yup.addMethod(yup.string, "cardValidity", cardValidity)

export const orderSchema = yup.object({
  customer: yup.string().required(),
  from: yup.string().required(),
  message: yup.string().required(),
  customerEmail: yup.string().email().required(),
  customerPhone: yup.string().min(15).required(),
  customerCpf: yup.string().cpf().required(),
  cardName: yup.string().required(),
  cardNumber: yup.string().required(),
  cvv: yup.string().min(3).required(),
  cardValidity: yup.string().cardValidity().required(),
  installments: yup.string().required()
})
