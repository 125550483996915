import colors from "../../utils/styles/colors";
import { Text } from "../Text";
import { ContainerCard, ContainerText, Icon, TextIcon } from "./styled";

export function CardHowWorks({ icon, text, note }) {
  return (
    <>
      <ContainerCard>
        <Icon style={{ backgroundColor: colors.blue }}>
          <TextIcon> 1 </TextIcon>
        </Icon>
        <ContainerText>
          <Text text='É muito fácil encomendar seu vídeo.' color={colors.gray} />
          <Text text='Primeiro clique em "eu quero" para selecionar' color={colors.gray} />
        </ContainerText>
      </ContainerCard>
      <ContainerCard>
        <Icon style={{ backgroundColor: colors.green }}>
          <TextIcon> 2 </TextIcon>
        </Icon>
        <ContainerText>
          <Text text='Depois, diga pra Giovanna o nome da pessoa que vai receber o material e qual a mensagem você quer que seja reproduzida pela artista.' color={colors.gray} />
          <Text text='Ps: Pode ser você também, por quê não?' color={colors.gray} />
        </ContainerText>
      </ContainerCard>
      <ContainerCard>
        <Icon style={{ backgroundColor: colors.yellow }}>
          <TextIcon> 3 </TextIcon>
        </Icon>
        <ContainerText>
          <Text text='Prontinho! Agora é só aguardar seu vídeo chegar e homenagear a pessoa que você deseja. ;)' color={colors.gray} />
        </ContainerText>
      </ContainerCard>
    </>
  )
}
