import styled from "styled-components";

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);
`;

export const IconApproved = styled.img`
  width: 100px;
  margin: 20px;
`;
