import React, { useState } from "react";
import './style.css';

import { ToastContainer, toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup"
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import '@vime/core/themes/default.css';
import { Header } from "../../components/Header";
import { ContainerEmoji, ContainerFormMessage, ContainerPrice, ContainerSectionArtist, ContainerSectionEmoticons, ContainerSectionFinality, ContainerSectionHowWorks, ContainerSectionMessage, ContainerSectionMore, ContainerSectionReadyToThrill, ContainerTextSectionArtist, ContainerTextSectionFinality, ContainerVideoMessage, FormMessage, ImageSectionArtist, ImageSectionFinality, LabelInputMessage, LongInputMessage, SectionArtist, SectionEmoticons, SectionFinality, SectionHowWorks, SectionMessage, SectionMore, SectionReadyToThrill, SelectInputMessage, ShortInputMessage, TextAreaMessage, TitleFormMessage, VideoMessage } from "./styled";
import giovannaAtonelli from "../../assets/images/giovanna_antonelli.png";
import giovannaAtonelliVideo from "../../assets/videos/giovanna_antonelli.mp4";
import giovannaAtonelliPng from "../../assets/images/giovanna_antonelli_sem_fundo.png";
import phone from "../../assets/images/phone.png";
import { Title } from "../../components/Title";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import colors from "../../utils/styles/colors";
import { CardHowWorks } from "../../components/CardHowWorks";
import { CardEmoji } from "../../components/CardEmoji";
import { useNavigate } from "react-router";

import emojiSmile from "../../assets/emoji/emoji_smile.png";
import emojiFun from "../../assets/emoji/emoji_fun.png";
import emojiLike from "../../assets/emoji/emoji_like.png";
import emojiLove from "../../assets/emoji/emoji_love.png";
import emojiPeace from "../../assets/emoji/emoji_peace.png";
import emojiStar from "../../assets/emoji/emoji_star.png";
import { Footer } from "../../components/Footer";
import { DefaultUi, Player, Video } from "@vime/react";
import { maskCardNumber, maskCardValidity, maskCpf, maskPhone } from "../../utils/mask";
import { store } from "../../services/order";
import { orderSchema } from "../../utils/schemas/orderSchema";

export function Home() {

  const navigate = useNavigate()


  const { register, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(orderSchema) })
  const [blockSubmit, setBlockSubmit] = useState(false)
  
  const onSubmit = async (data) => {
    setBlockSubmit(true)
    toast.loading('Finalizando a compra')
    try {
      await store(data)
      reset()
      navigate('/success')
    } catch (error) {
      console.log({ error })
      setBlockSubmit(false)
      toast.error(error.response.data.message, { onClose: () => toast.dismiss() })
      reset()
    }
  }

  return(
    <>
      <Header />
      <ContainerSectionMessage id="form">
        <SectionMessage>
          <ContainerVideoMessage>
            <Player theme="dark" style={{ width: '80%', '--vm-player-theme': `${colors.red}` }}>
              <Video poster={giovannaAtonelli}>
                <source data-src={giovannaAtonelliVideo} type="video/mp4" />
              </Video>
              <DefaultUi />
            </Player>
          </ContainerVideoMessage>  
          <ContainerFormMessage>
            <TitleFormMessage> ADAPTE OU ESCREVA AGORA A MENSAGEM PARA EMOCIONAR UM AMIGO OU FAMILIAR COM A GIO. </TitleFormMessage>
            <FormMessage onSubmit={handleSubmit(onSubmit)}>
              <LabelInputMessage for="customer"> De: </LabelInputMessage>
              <ShortInputMessage id="customer" {...register("customer", { required: true })} className={ !!errors.customer ? "borderRed" : "" } />

              <LabelInputMessage for="from"> Para: </LabelInputMessage>
              <ShortInputMessage id="from" {...register("from", { required: true })} className={ !!errors.from ? "borderRed" : "" } />
              
              <LabelInputMessage for="message"> Mensagem: </LabelInputMessage>
              <TextAreaMessage id="message" {...register("message", { required: true })} className={ !!errors.message ? "borderRed" : "" } />
              
              <LabelInputMessage for="customerEmail"> Seu e-mail: </LabelInputMessage>
              <LongInputMessage id="customerEmail"type="email" {...register("customerEmail", { required: true })} className={ !!errors.customerEmail ? "borderRed" : "" } />
              
              <LabelInputMessage for="customerPhone"> Seu celular: </LabelInputMessage>
              <ShortInputMessage id="customerPhone" {...register("customerPhone", { required: true })} onChange={maskPhone} className={ !!errors.customerPhone ? "borderRed" : "" } />
              
              <LabelInputMessage for="customerCpf"> Seu CPF: </LabelInputMessage>
              <ShortInputMessage id="customerCpf" {...register("customerCpf", { required: true })} onChange={maskCpf} className={ !!errors.customerCpf ? "borderRed" : "" } />
              
              <LabelInputMessage for="cardName"> Nome completo no cartão: </LabelInputMessage>
              <ShortInputMessage id="cardName" {...register("cardName", { required: true })} onChange={(event) => event.currentTarget.value = event.currentTarget.value.toUpperCase()} className={ !!errors.cardName ? "borderRed" : "" } />
              
              <LabelInputMessage for="cardNumber"> Nº cartão: </LabelInputMessage>
              <LongInputMessage id="cardNumber" {...register("cardNumber", { required: true })} onChange={maskCardNumber} className={ !!errors.cardNumber ? "borderRed" : "" } />
              
              <LabelInputMessage for="cvv"> CVV: </LabelInputMessage>
              <ShortInputMessage id="cvv" style={{width: "60px"}} {...register("cvv", { required: true })} maxLength="3" className={ !!errors.cvv ? "borderRed" : "" } />
              
              <LabelInputMessage for="cardValidity"> Validade: </LabelInputMessage>
              <ShortInputMessage id="cardValidity" style={{width: "60px"}} {...register("cardValidity", { required: true })} onChange={maskCardValidity} maxLength="5" className={ !!errors.cardValidity ? "borderRed" : "" } />

              <SelectInputMessage {...register("installments", { required: true })} className={ !!errors.installments ? "borderRed" : "" } >
                <option value="" disabled selected>Parcelas</option>
                <option value="1">1 vez de R$ 500,00</option>
                <option value="2">2 vezes de R$ 250,00</option>
                <option value="3">3 vezes de R$ 166,67 </option>
              </SelectInputMessage>

              <ContainerPrice>
                <Text text='R$ 500,00' />
              </ContainerPrice>

              <Button text="Finalizar compra" type="submit" disabled={blockSubmit} />
            </FormMessage>
          </ContainerFormMessage>
        </SectionMessage>
      </ContainerSectionMessage>
      <SectionMore>
          <ContainerSectionMore>
            <Title title="EMOCIONE ALGUÉM ESPECIAL PARA VOCÊ COM UM VÍDEO PERSONALIZADO DA GIOVANNA ANTONELLI." />
            <Text text="É isso mesmo. De um jeito bem simples e personalizado, você consegue encomendar uma gravação da Giovanna Antonelli para presentear com carinho quem você ama." />
            <Text text="Bora lá?" />
            <Button text="QUERO EMOCIONAR ALGUÉM ESPECIAL AGORA" onClick={() => window.location="#form"}/>
          </ContainerSectionMore>
        </SectionMore>
        <SectionHowWorks>
          <ContainerSectionHowWorks>
            <Title title="Como funciona?" color={colors.red} />
            <CardHowWorks />
            <Button  text="QUERO EMOCIONAR ALGUÉM ESPECIAL AGORA" onClick={() => window.location="#form"}/>
          </ContainerSectionHowWorks>
        </SectionHowWorks>
        <SectionFinality>
          <ContainerSectionFinality>
            <ImageSectionFinality src={phone} alt="phone" />
            <ContainerTextSectionFinality>
              <Title title="Qual a finalidade disso?"/>
              <Text text="Quando o presente é personalizado, você consegue dar um toque exclusivo e pessoal à ele. Desta forma, a pessoa que recebe o presente se sente mais valorizada e a partir dai, aumenta-se o valor sentimental do item."/>
              <Text text="Chegou a hora de você criar boas lembranças em boas ocasiões."/>
            </ContainerTextSectionFinality>
          </ContainerSectionFinality>
        </SectionFinality>
        <SectionArtist>
          <ContainerSectionArtist>
            <ContainerTextSectionArtist>
              <Title title="A GIOVANNA ANTONELLI" color={colors.black}/>
              <Text text="Artista global, com 30 anos de carreira, divididos entre TV, Cinema e Teatro. Em sequências de papéis centrais de sucesso, Giovanna é premiada e reconhecida internacionalmente." color={colors.black} />
              <Text text="Em paralelo, também é empresária e tem projetos em diferentes setores." color={colors.black} />
              <Button text="QUERO EMOCIONAR ALGUÉM ESPECIAL AGORA" onClick={() => window.location="#form"}/>
            </ContainerTextSectionArtist>
            <ImageSectionArtist src={giovannaAtonelliPng} alt="Giovanna Antonelli" />
          </ContainerSectionArtist>
        </SectionArtist>
        <SectionEmoticons>
          <ContainerSectionEmoticons>
            <Title title="USE E ABUSE DA SUA CRIATIVIDADE PARA ENCANTAR QUEM VOCÊ QUISER" />
            <ContainerEmoji>
              <CardEmoji emoji={emojiSmile} text="Um agradecimento"/>
              <CardEmoji emoji={emojiPeace} text="Uma mensagem motivacional"/>
              <CardEmoji emoji={emojiLove} text="Uma bela poesia"/>
              <CardEmoji emoji={emojiLike} text="Um verso de música"/>
              <CardEmoji emoji={emojiFun} text="Uma trolagem rs"/>
              <CardEmoji emoji={emojiStar} text="E muito mais!"/>
            </ContainerEmoji>
          </ContainerSectionEmoticons>
        </SectionEmoticons>
        <SectionReadyToThrill>
          <ContainerSectionReadyToThrill>
            <Title title="PRONTO PARA EMOCIONAR ALGUÉM COM A GIOVANNA?" color={colors.black}/>
            <Button text="ENTÃO VAMOS COMEÇAR!" onClick={() => window.location="#form"}/>
          </ContainerSectionReadyToThrill>
        </SectionReadyToThrill>
        <ToastContainer />
      <Footer />
    </>
  )
}
