import { ContainerFooter, Logo } from "./styled"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.svg"

export function Footer() {
  const history = useNavigate()
  return (
    <ContainerFooter>
      <Logo src={logo} alt="logo" onClick={() => history('/')} />
    </ContainerFooter>
  )
}
