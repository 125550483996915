import { ContainerHeader, Logo } from "./styled"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.svg"

export function Header() {
  const history = useNavigate ()

  return (
    <ContainerHeader onClick={() => history('/') }>
      <Logo src={logo} alt="logo" />
    </ContainerHeader>
  )
}
