import styled from "styled-components";
import colors from "../../utils/styles/colors";

export const ContainerCard = styled.div`
  width: 450px;
  height: 200px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
  }
`;

export const Icon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextIcon = styled.h1`
  color: ${colors.white};
  font-size: 60px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 800;
`;

export const ContainerText = styled.div`
  width: 330px;
  text-align: left;
  font-weight: 600;
  @media(max-width: 800px) {
    width: 275px;
    font-size: 14px;
    padding: 0px 10px;
  }
`;
