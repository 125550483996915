import styled from "styled-components";
import backgroundMessage from "../../assets/images/background_message.png";
import colors from "../../utils/styles/colors";

export const ContainerSectionMessage = styled.div`
  width: 100%;
  background-image: url(${backgroundMessage});
  background-color: ${colors.gray};
`;

export const SectionMessage = styled.section`
  width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    padding: 10px
  }
`;

export const VideoMessage = styled.img`
  width: 300px;
  margin: 10px;
`;

export const ContainerFormMessage = styled.div`
  width: 400px;
  margin: 10px;
`;

export const TitleFormMessage = styled.h1`
  width: 370px;
  margin: auto;
  color: ${colors.white};
  font-family: "Ubuntu", sans-serif;
  font-weight: 800;
  font-size: 16px;
`;

export const FormMessage = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ShortInputMessage = styled.input`
  width: 150px;
  color: ${colors.white};
  border: none;
  background-color: ${colors.gray};
  border-bottom: 1px solid ${colors.white};
  font-family: "Ubuntu", sans-serif;
  outline: none;
  margin-top: 20px;
  @media(max-width: 800px) {
    width: 125px;
  }
`;

export const LongInputMessage = styled.input`
  width: 310px;
  color: ${colors.white};
  border: none;
  background-color: ${colors.gray};
  border-bottom: 1px solid ${colors.white};
  font-family: "Ubuntu", sans-serif;
  outline: none;
  margin-top: 20px;
  @media(max-width: 800px) {
    width: 275px;
  }
`;

export const LabelInputMessage = styled.label`
  margin-top: 20px;
  color: ${colors.white};
  font-family: "Ubuntu", sans-serif;
`;

export const TextAreaMessage = styled.textarea`
  width: 380px;
  margin: auto;
  height: 70px;
  resize: none;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 8px;
  font-family: "Ubuntu", sans-serif;
  @media(max-width: 800px) {
    width: 350px;
  }
`;

export const SelectInputMessage = styled.select`
  width: 125px;
  outline: none;
  border: 1px solid ${colors.white};
  background-color: ${colors.gray};
  color: ${colors.white};
  text-align: center;
  border-radius: 8px;
  @media(max-width: 800px) {
    width: 85px;
  }
`;

export const ContainerVideoMessage = styled.div`
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 800px) {
    width: 100%;
  }
`;

export const SectionMore = styled.div`
  width: 100%;
  padding: 80px 0px;
  background-color: ${colors.blue};
`;

export const ContainerSectionMore = styled.section`
  width: 800px;
  margin: auto;
  text-align: center;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    padding: 0px 10px;
  }
`;

export const SectionHowWorks = styled.div`
  padding: 80px 0px;
  background-image: url(${backgroundMessage});
  background-color: ${colors.white};
`;

export const ContainerSectionHowWorks = styled.section`
  width: 800px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    padding: 0px 10px;
  }
`;

export const SectionFinality = styled.div`
  width: 100%;
  height: 400px;
  background-color: ${colors.blue};
  padding-top: 30px;
`;

export const ContainerSectionFinality = styled.section`
  width: 1100px;
  margin: auto;
  display: flex;
  position: relative;
  top: -130px;
  flex-direction: row;
  justify-content: left;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    padding: 0px 10px;
    top: 0;
  }
`;

export const ImageSectionFinality = styled.img`
  width: 700px;
  height: auto;
  position: relative;
  left: -100px;
  @media(max-width: 800px) {
    display: none;
  }

`;

export const ContainerTextSectionFinality = styled.div`
  display: flex;
  height: 300px;
  flex-direction: column;
  position: relative;
  left: -150px;
  top: 130px;
  @media(max-width: 800px) {
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
  }
`;

export const SectionArtist = styled.div`
  width: 100%;
  padding: 60px 0;
  height: 310px;
  background-color: ${colors.white};
`;

export const ContainerSectionArtist = styled.section`
  width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  top: -230px;
  @media(max-width: 800px) {
    width: 100%;
    top: 0;
  }  
`;

export const ContainerTextSectionArtist = styled.div`
  display: flex;
  width: 500px;
  height: 200px;
  flex-direction: column;
  text-align: center;
  position: relative;
  top: 230px;
  @media(max-width: 800px) {
    width: 100%;
    top: 0;
  }

`;

export const ImageSectionArtist = styled.img`
  width: 400px;
  @media(max-width: 800px) {
    display: none;
  }
`;

export const SectionEmoticons = styled.div`
  width: 100%;
  padding: 60px 0px;
  background-color: ${colors.green};
`;

export const ContainerSectionEmoticons = styled.section`
  width: 1100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    text-align: center;
    padding: 0px 10px;
  }
`;

export const ContainerEmoji = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const SectionReadyToThrill = styled.div`
  width: 100%;
  padding: 40px 0px;
`;

export const ContainerSectionReadyToThrill = styled.section`
  width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(max-width: 800px) {
    width: calc(100% - 20px);
    padding: 0px 10px;
    text-align: center;
  }
`;

export const ContainerPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;
