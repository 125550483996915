import styled from "styled-components"
import colors from "../../utils/styles/colors";

export function Text({ text, color }) {
  const Text = styled.p`
    font-family: "Ubuntu", sans-serif;
    color: ${color ? color : colors.white}
  `;
  
  return(
    <Text>
      {text}
    </Text>
  )  
}