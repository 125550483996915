import styled from "styled-components";
import colors from "../../utils/styles/colors";

export const ContainerHeader = styled.div`
  width: 100%;
  background-color: ${colors.red};
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 150px;
  height: 50px;
`;
